import {Button,Form,Input} from "antd";
import firebase from "firebase/app";
import {useState} from "react";
import {useIntl} from "react-intl";
import {Link,useHistory,useLocation} from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import {useAuth} from "../authentication";
import AppNotificationContainer from "../components/AppNotificationContainer";
import {auth} from "../firebase";

const FormItem = Form.Item;

const SignIn = () => {
  const intl=useIntl();
  const { isLoading, error, userLogin, setAuthUser } = useAuth();
  const [result, setResult] = useState("");
  const history = useHistory();
  const location = useLocation();

  const onFinishFailed = (errorInfo) => {};

  const onFinish = (values) => {
    setResult("");
    userLogin(values, (err) => {
      if (err && err.code) {
        switch (err.code) {
          case "auth/user-not-found":
          case "auth/wrong-password":
            setResult(intl.formatMessage({id:"page.login.invalid_email_or_password"}));
            break;
          case "auth/operation-not-allowed":
            setResult("Kullanıcı hesabı aktif değil!");
            break;
          case "auth/user-disabled":
            setResult("Kullanıcı hesabı aktif değil(1)!");
            break;
          case "auth/operation-not-allowed":
            setResult("Kullanıcı hesabı aktif değil(2)!");            
            break;
          case "auth/invalid-credential":
            setResult("Kullanıcı bilgileri hatalı!");            
            break;
        }
      }
      console.log(err);
    });
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg"></div>

            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.png" />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              {result && result !== "" && (
                <Form.Item>
                  <div class="ant-form-item-explain-error">
                    <div role="alert">{result}</div>
                  </div>
                </Form.Item>
              )}
              <Form.Item
                rules={[{ required: true, message: intl.formatMessage({id:"page.login.invalid_email"}) }]}
                name="email"
              >
                <Input  placeholder={intl.formatMessage({id:"page.login.email"})}  type="email" />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: intl.formatMessage({id:"page.login.please_enter_your_password"})}]}
                name="password"
              >
                <Input type="password"  placeholder={intl.formatMessage({id:"page.login.password"})}  />
              </Form.Item>
              {/* <Form.Item>
                <Checkbox><IntlMessages id="appModule.iAccept"/></Checkbox>
                <span className="gx-signup-form-forgot gx-link"><IntlMessages
                  id="appModule.termAndCondition"/></span>
              </Form.Item> */}

              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="page.login.login"/>
                </Button>
                <span><IntlMessages id="page.login.or"/></span> <Link to={`/signup${location.search??''}`}><IntlMessages id="page.login.signup"/></Link>
                <Link
                className="forget-password-link"
                  to="/forgot-password"
               
                >
                  <IntlMessages id="page.login.lost_password"/>
                </Link>
              </Form.Item>

              <Button
                style={{
                  display: "flex",
                  gap: 10,
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                }}
                onClick={() => {
                  var provider = new firebase.auth.GoogleAuthProvider();
                  auth.signInWithPopup(provider).then(() => {
                    history.push("/dashboard");
                  });
                }}
              >
                <img height="16" src="/assets/images/google.png" />
                <IntlMessages id="page.login.signin_with_google"/>
              </Button>
            </Form>
          </div>
          <AppNotificationContainer loading={isLoading} error={error} />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
