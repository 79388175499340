import axios from "axios";
const key = "ZephlexSymbols_";
export const setSymbols = () => {
    axios({
        url: 'https://europe-west1-algo-senti.cloudfunctions.net/zephlex-symbols',
        method: 'get',
        headers: {
            "x-api-key": "3ceb2ab173458a6e75df81c01dac15e461db173f6f4e9a63556221324b362afa",
        }
    })
        .then(({ data }) => {
            localStorage.setItem(key, JSON.stringify(data.symbols.map((x) => { return x.Name })));
            console.log("SYMBOLS LOADED");
        })
        .catch(function (error) {
            console.log("SYMBOLS ERROR");
        });
};

const getSymbol = (ix) => {
    const symbols = JSON.parse(localStorage.getItem(key));
    if (symbols && symbols[ix])
        return symbols[ix];

    switch (ix) {
        case "x030":
            return symbols030_statics();
        case "x070":
            return symbols070_statics();
        case "x200":
            return symbols200_statics();
        case "x400":
            return symbols400_statics();
        case "x030_weekly":
            return symbols030_weekly_statics();
        case "x070_weekly":
            return symbols070_weekly_statics();
        case "x200_weekly":
            return symbols200_weekly_statics();
        case "x400_weekly":
            return symbols400_weekly_statics();
    }

}



const symbols030_statics = () => {
    return [
        "AKBNK",
        "ALARK",
        "ARCLK",
        "ASELS",
        "ASTOR",
        "BIMAS",
        "EKGYO",
        "ENKAI",
        "EREGL",
        "FROTO",
        "GARAN",
        "GUBRF",
        "HEKTS",
        "ISCTR",
        "KCHOL",
        "KONTR",
        "KOZAL",
        "KRDMD",
        "ODAS",
        "OYAKC",
        "PETKM",
        "PGSUS",
        "SAHOL",
        "SASA",
        "SISE",
        "TCELL",
        "THYAO",
        "TOASO",
        "TUPRS",
        "YKBNK",
    ]
};

const symbols070_statics = () => {
    return [
        "AEFES",
        "AGHOL",
        "AHGAZ",
        "AKCNS",
        "AKFYE",
        "AKSA",
        "AKSEN",
        "ALBRK",
        "ALFAS",
        "BERA",
        "BIENY",
        "BRSAN",
        "BRYAT",
        "BUCIM",
        "CANTE",
        "CCOLA",
        "CIMSA",
        "CWENE",
        "DOAS",
        "DOHOL",
        "ECILC",
        "ECZYT",
        "EGEEN",
        "ENJSA",
        "EUPWR",
        "EUREN",
        "GESAN",
        "GWIND",
        "HALKB",
        "IPEKE",
        "ISDMR",
        "ISGYO",
        "ISMEN",
        "IZMDC",
        "KARSN",
        "KAYSE",
        "KCAER",
        "KMPUR",
        "KONYA",
        "KORDS",
        "KOZAA",
        "MAVI",
        "MGROS",
        "MIATK",
        "OTKAR",
        "PENTA",
        "QUAGR",
        "SKBNK",
        "SMRTG",
        "SOKM",
        "TAVHL",
        "TKFEN",
        "TSKB",
        "TTKOM",
        "TTRAK",
        "TUKAS",
        "ULKER",
        "VAKBN",
        "VESTL",
        "YEOTK",
        "YYLGD",
        "ZOREN",
        "ASGYO",
        "BIOEN",
        "BOBET",
        "ENERY",
        "IZENR",
        "KLSER",
        "SDTTR",
        "TATEN",
    ];
};


const symbols200_statics = () => {
    return [
        "A1CAP",
        "ADEL",
        "ADESE",
        "AFYON",
        "AGESA",
        "AKGRT",
        "AKSGY",
        "ALCTL",
        "ALGYO",
        "ALKIM",
        "ANHYT",
        "ANSGR",
        "ARASE",
        "ARDYZ",
        "ASUZU",
        "ATAKP",
        "ATATP",
        "AYDEM",
        "AYGAZ",
        "AZTEK",
        "BAGFS",
        "BASGZ",
        "BFREN",
        "BIGCH",
        "BRISA",
        "BRLSM",
        "BTCIM",
        "CEMAS",
        "CEMTS",
        "CLEBI",
        "CVKMD",
        "DAPGM",
        "DESA",
        "DEVA",
        "DGNMO",
        "EBEBK",
        "EGGUB",
        "EKSUN",
        "ELITE",
        "ERBOS",
        "ERCB",
        "ESCAR",
        "FENER",
        "FORTE",
        "FZLGY",
        "GEDIK",
        "GIPTA",
        "GLCVY",
        "GOKNR",
        "GOLTS",
        "GOODY",
        "GOZDE",
        "GRSEL",
        "GRTRK",
        "GSDHO",
        "GSRAY",
        "HEDEF",
        "HLGYO",
        "HTTBT",
        "HUNER",
        "IHAAS",
        "INDES",
        "INFO",
        "INVEO",
        "INVES",
        "ISFIN",
        "ISSEN",
        "JANTS",
        "KAREL",
        "KATMR",
        "KERVT",
        "KLGYO",
        "KLKIM",
        "KLRHO",
        "KONKA",
        "KOPOL",
        "KRDMA",
        "KRDMB",
        "KRVGD",
        "KTLEV",
        "KUYAS",
        "KZGYO",
        "LIDER",
        "LOGO",
        "MAGEN",
        "MARTI",
        "MEDTR",
        "MIPAZ",
        "MOBTL",
        "MPARK",
        "NATEN",
        "NTGAZ",
        "NTHOL",
        "NUHCM",
        "OFSYM",
        "ONCSM",
        "OYYAT",
        "OZKGY",
        "PAMEL",
        "PARSN",
        "PASEU",
        "PNLSN",
        "POLHO",
        "POLTK",
        "RYGYO",
        "RYSAS",
        "SARKY",
        "SAYAS",
        "SELEC",
        "SNICA",
        "SRVGY",
        "SUNTK",
        "SUWEN",
        "TARKM",
        "TATGD",
        "TEZOL",
        "TKNSA",
        "TMSN",
        "TNZTP",
        "TRCAS",
        "TRGYO",
        "TURSG",
        "ULUUN",
        "VAKFN",
        "VAKKO",
        "VERUS",
        "VKGYO",
        "YATAS",
        "YGGYO",
        "YUNSA",
        "ZRGYO",
        "AKFGY",
        "GENIL",
        "GLYHO",
        "IMASM",
        "KZBGY",
        "PSGYO",
        "SNGYO",
        "VESBE",
    ]
};

const symbols400_statics = () => {
    return [
        "ACSEL",
        "AGYO",
        "AKENR",
        "AKMGY",
        "AKSUE",
        "AKYHO",
        "ALCAR",
        "ALKA",
        "ALMAD",
        "ANELE",
        "ANGEN",
        "ARENA",
        "ARSAN",
        "ARZUM",
        "ATAGY",
        "ATEKS",
        "ATLAS",
        "AVGYO",
        "AVHOL",
        "AVOD",
        "AYCES",
        "AYEN",
        "BAKAB",
        "BANVT",
        "BARMA",
        "BAYRK",
        "BEYAZ",
        "BIZIM",
        "BJKAS",
        "BLCYT",
        "BMSCH",
        "BMSTL",
        "BNTAS",
        "BOSSA",
        "BRKSN",
        "BRKVY",
        "BSOKE",
        "BURCE",
        "BURVA",
        "BVSAN",
        "BYDNR",
        "CELHA",
        "CEOEM",
        "CMBTN",
        "CONSE",
        "COSMO",
        "CRDFA",
        "CRFSA",
        "CUSAN",
        "DAGHL",
        "DAGI",
        "DARDL",
        "DENGE",
        "DERHL",
        "DERIM",
        "DESPC",
        "DGATE",
        "DGGYO",
        "DITAS",
        "DMSAS",
        "DNISI",
        "DOBUR",
        "DOCO",
        "DOGUB",
        "DOKTA",
        "DURDO",
        "DYOBY",
        "DZGYO",
        "EDATA",
        "EDIP",
        "EGEPO",
        "EGPRO",
        "EGSER",
        "EMKEL",
        "ENSRI",
        "EPLAS",
        "ERSU",
        "ESCOM",
        "ESEN",
        "EYGYO",
        "FADE",
        "FLAP",
        "FMIZP",
        "FONET",
        "FORMT",
        "FRIGO",
        "GARFA",
        "GEDZA",
        "GENTS",
        "GEREL",
        "GLBMD",
        "GLRYH",
        "GMTAS",
        "GRNYO",
        "GSDDE",
        "GZNMI",
        "HATEK",
        "HDFGS",
        "HKTM",
        "HUBVC",
        "HURGZ",
        "ICBCT",
        "IDEAS",
        "IEYHO",
        "IHEVA",
        "IHGZT",
        "IHLAS",
        "IHLGM",
        "IHYAY",
        "INGRM",
        "INTEM",
        "ISGSY",
        "ISKPL",
        "ISYAT",
        "IZFAS",
        "IZINV",
        "KAPLM",
        "KARTN",
        "KARYE",
        "KFEIN",
        "KGYO",
        "KIMMR",
        "KLMSN",
        "KLSYN",
        "KNFRT",
        "KRGYO",
        "KRONT",
        "KRPLS",
        "KRSTL",
        "KRTEK",
        "KTSKR",
        "KUTPO",
        "LIDFA",
        "LINK",
        "LKMNH",
        "LRSHO",
        "LUKSK",
        "MAALT",
        "MACKO",
        "MAKIM",
        "MAKTK",
        "MANAS",
        "MEGAP",
        "MEPET",
        "MERCN",
        "MERIT",
        "MERKO",
        "METRO",
        "METUR",
        "MNDRS",
        "MNDTR",
        "MRGYO",
        "MRSHL",
        "MSGYO",
        "MTRKS",
        "MZHLD",
        "NETAS",
        "NIBAS",
        "NUGYO",
        "OBASE",
        "ORCAY",
        "ORGE",
        "OSMEN",
        "OSTIM",
        "OYAYO",
        "OYLUM",
        "OZGYO",
        "OZRDN",
        "OZSUB",
        "PAGYO",
        "PAPIL",
        "PCILT",
        "PEGYO",
        "PEKGY",
        "PENGD",
        "PETUN",
        "PINSU",
        "PKART",
        "PKENT",
        "PLTUR",
        "PNSUT",
        "PRDGS",
        "PRKAB",
        "PRKME",
        "PRZMA",
        "PSDTC",
        "RALYH",
        "RAYSG",
        "RNPOL",
        "RODRG",
        "RTALB",
        "RUBNS",
        "SAFKR",
        "SAMAT",
        "SANEL",
        "SANFM",
        "SANKO",
        "SEGYO",
        "SEKFK",
        "SEKUR",
        "SELVA",
        "SEYKM",
        "SILVR",
        "SKTAS",
        "SMART",
        "SOKE",
        "SONME",
        "TDGYO",
        "TEKTU",
        "TERA",
        "TETMT",
        "TGSAS",
        "TLMAN",
        "TMPOL",
        "TRILC",
        "TSGYO",
        "TSPOR",
        "TUCLK",
        "TUREX",
        "TURGG",
        "UFUK",
        "ULAS",
        "ULUFA",
        "ULUSE",
        "UNLU",
        "USAK",
        "VBTYZ",
        "VERTU",
        "VKFYO",
        "VKING",
        "YAPRK",
        "YAYLA",
        "YESIL",
        "YGYO",
        "YKSLN",
        "YYAPI",
        "ZEDUR",
    ]
}

const symbols030_weekly_statics = () => {
    let outs = ["ASTOR"];
    let weekly = symbols030().filter(x => { return !outs.includes(x); });
    return weekly;
}

const symbols070_weekly_statics = () => {
    let outs = ["AHGAZ", "AKFYE", "ALFAS", "BIENY", "CWENE", "EUPWR", "EUREN", "KAYSE", "KCAER", "ASGYO", "ENERY", "IZENR", "KLSER", "SDTTR", "TATEN",];
    let weekly = symbols070().filter(x => { return !outs.includes(x); });
    return weekly;
}

const symbols200_weekly_statics = () => {
    let outs = ["A1CAP", "ATAKP", "AZTEK", "BIGCH", "CVKMD", "EBEBK", "EKSUN", "FORTE", "FZLGY", "GIPTA", "GOKNR", "GRTRK", "KLRHO", "KOPOL", "KTLEV", "KZGYO", "OFSYM", "ONCSM", "PASEU", "SNICA", "TARKM", "TNZTP",];
    let weekly = symbols200().filter(x => { return !outs.includes(x); });
    return weekly;
}

const symbols400_weekly_statics = () => {
    let outs = ["BARMA", "BRKVY", "BVSAN", "BYDNR", "EYGYO", "HKTM", "KRPLS", "MACKO", "MAKIM", "OBASE", "OZSUB", "PLTUR", "PRDGS", "RUBNS", "SEGYO", "SOKE", "TERA",];
    let weekly = symbols400().filter(x => { return !outs.includes(x); });
    return weekly;
}

export const symbolsxHarz = () => {
    return [
        "ADGYO",
        "DMRGD",
        "DOFER",
        "HATSN",
        "MEKAG",
        "REEDR",
        "TABGD",
        "VRGYO",
    ]
};

export const symbols500 = () => {
    return [
        "A1CAP",
        "ACSEL",
        "ADEL",
        "ADESE",
        "AFYON",
        "AGESA",
        "AGYO",
        "AKENR",
        "AKGRT",
        "AKMGY",
        "AKSGY",
        "AKSUE",
        "AKYHO",
        "ALCAR",
        "ALCTL",
        "ALGYO",
        "ALKA",
        "ALKIM",
        "ALMAD",
        "ANELE",
        "ANGEN",
        "ANHYT",
        "ANSGR",
        "ARASE",
        "ARDYZ",
        "ARENA",
        "ARSAN",
        "ARZUM",
        "ASUZU",
        "ATAGY",
        "ATATP",
        "ATEKS",
        "ATLAS",
        "AVGYO",
        "AVHOL",
        "AVOD",
        "AYCES",
        "AYDEM",
        "AYEN",
        "AYGAZ",
        "AZTEK",
        "BAGFS",
        "BAKAB",
        "BANVT",
        "BARMA",
        "BASGZ",
        "BAYRK",
        "BEYAZ",
        "BFREN",
        "BIGCH",
        "BIOEN",
        "BIZIM",
        "BJKAS",
        "BLCYT",
        "BMSCH",
        "BMSTL",
        "BNTAS",
        "BOBET",
        "BOSSA",
        "BRISA",
        "BRKSN",
        "BRKVY",
        "BRLSM",
        "BSOKE",
        "BTCIM",
        "BURCE",
        "BURVA",
        "BVSAN",
        "CELHA",
        "CEMAS",
        "CEMTS",
        "CEOEM",
        "CLEBI",
        "CMBTN",
        "CONSE",
        "COSMO",
        "CRDFA",
        "CRFSA",
        "CUSAN",
        "CVKMD",
        "DAGHL",
        "DAGI",
        "DAPGM",
        "DARDL",
        "DENGE",
        "DERHL",
        "DERIM",
        "DESA",
        "DESPC",
        "DEVA",
        "DGATE",
        "DGGYO",
        "DGNMO",
        "DITAS",
        "DMSAS",
        "DNISI",
        "DOBUR",
        "DOCO",
        "DOGUB",
        "DOKTA",
        "DURDO",
        "DYOBY",
        "DZGYO",
        "EDATA",
        "EDIP",
        "EGEPO",
        "EGGUB",
        "EGPRO",
        "EGSER",
        "EKSUN",
        "ELITE",
        "EMKEL",
        "ENSRI",
        "EPLAS",
        "ERBOS",
        "ERCB",
        "ERSU",
        "ESCAR",
        "ESCOM",
        "ESEN",
        "ETILR",
        "EUHOL",
        "EYGYO",
        "FADE",
        "FENER",
        "FLAP",
        "FMIZP",
        "FONET",
        "FORMT",
        "FORTE",
        "FRIGO",
        "GARFA",
        "GEDIK",
        "GEDZA",
        "GENTS",
        "GEREL",
        "GLBMD",
        "GLCVY",
        "GLRYH",
        "GMTAS",
        "GOKNR",
        "GOLTS",
        "GOODY",
        "GOZDE",
        "GRNYO",
        "GRSEL",
        "GRTRK",
        "GSDDE",
        "GSDHO",
        "GSRAY",
        "GZNMI",
        "HATEK",
        "HDFGS",
        "HEDEF",
        "HKTM",
        "HLGYO",
        "HTTBT",
        "HUBVC",
        "HUNER",
        "HURGZ",
        "ICBCT",
        "IDEAS",
        "IEYHO",
        "IHAAS",
        "IHEVA",
        "IHGZT",
        "IHLAS",
        "IHLGM",
        "IHYAY",
        "INDES",
        "INFO",
        "INGRM",
        "INTEM",
        "INVEO",
        "INVES",
        "ISFIN",
        "ISGSY",
        "ISKPL",
        "ISSEN",
        "ISYAT",
        "LRSHO",
        "IZFAS",
        "IZINV",
        "JANTS",
        "KAPLM",
        "KAREL",
        "KARTN",
        "KARYE",
        "KATMR",
        "KERVT",
        "KFEIN",
        "KGYO",
        "KIMMR",
        "KLGYO",
        "KLKIM",
        "KLMSN",
        "KLRHO",
        "KLSYN",
        "KNFRT",
        "KONKA",
        "KOPOL",
        "KRDMA",
        "KRDMB",
        "KRGYO",
        "KRONT",
        "KRPLS",
        "KRSTL",
        "KRTEK",
        "KRVGD",
        "KTLEV",
        "KTSKR",
        "KUTPO",
        "KUYAS",
        "LIDER",
        "LIDFA",
        "LINK",
        "LKMNH",
        "LOGO",
        "LUKSK",
        "MAALT",
        "MACKO",
        "MAGEN",
        "MAKIM",
        "MAKTK",
        "MANAS",
        "MARTI",
        "MEDTR",
        "MEGAP",
        "MEPET",
        "MERCN",
        "MERIT",
        "MERKO",
        "METRO",
        "METUR",
        "MIPAZ",
        "MNDRS",
        "MNDTR",
        "MOBTL",
        "MPARK",
        "MRGYO",
        "MRSHL",
        "MSGYO",
        "MTRKS",
        "MZHLD",
        "NATEN",
        "NETAS",
        "NIBAS",
        "NTGAZ",
        "NTHOL",
        "NUGYO",
        "NUHCM",
        "OBASE",
        "ONCSM",
        "ORCAY",
        "ORGE",
        "OSMEN",
        "OSTIM",
        "OYAYO",
        "OYLUM",
        "OYYAT",
        "OZGYO",
        "OZKGY",
        "OZRDN",
        "OZSUB",
        "PAGYO",
        "PAMEL",
        "PAPIL",
        "PARSN",
        "PASEU",
        "PCILT",
        "PEGYO",
        "PEKGY",
        "PENGD",
        "PETUN",
        "PINSU",
        "PKART",
        "PKENT",
        "PLTUR",
        "PNLSN",
        "PNSUT",
        "POLHO",
        "POLTK",
        "PRDGS",
        "PRKAB",
        "PRKME",
        "PRZMA",
        "PSDTC",
        "RALYH",
        "RAYSG",
        "RNPOL",
        "RODRG",
        "RTALB",
        "RUBNS",
        "RYGYO",
        "RYSAS",
        "SAFKR",
        "SAMAT",
        "SANEL",
        "SANFM",
        "SANKO",
        "SARKY",
        "SAYAS",
        "SDTTR",
        "SEGYO",
        "SEKFK",
        "SEKUR",
        "SELEC",
        "SELVA",
        "SEYKM",
        "SILVR",
        "SKTAS",
        "SMART",
        "SNICA",
        "SOKE",
        "SONME",
        "SRVGY",
        "SUNTK",
        "SUWEN",
        "TATGD",
        "TDGYO",
        "TEKTU",
        "TERA",
        "TETMT",
        "TEZOL",
        "TGSAS",
        "TKNSA",
        "TLMAN",
        "TMPOL",
        "TMSN",
        "TNZTP",
        "TRCAS",
        "TRGYO",
        "TRILC",
        "TSGYO",
        "TSPOR",
        "TUCLK",
        "TUREX",
        "TURGG",
        "TURSG",
        "UFUK",
        "ULAS",
        "ULUFA",
        "ULUSE",
        "ULUUN",
        "UNLU",
        "USAK",
        "VAKFN",
        "VAKKO",
        "VBTYZ",
        "VERTU",
        "VERUS",
        "VKFYO",
        "VKGYO",
        "VKING",
        "YAPRK",
        "YATAS",
        "YAYLA",
        "YESIL",
        "YGGYO",
        "YGYO",
        "YKSLN",
        "YUNSA",
        "YYAPI",
        "ZEDUR",
        "ZRGYO",

    ];
}

export const symbols500_weekly = () => {
    let outs = ["A1CAP", "AZTEK", "BARMA", "BIGCH", "BRKVY", "BVSAN", "CVKMD", "CWENE", "EKSUN", "EYGYO", "FORTE", "GOKNR", "GRTRK", "HKTM", "KAYSE", "KLRHO", "KOPOL", "KRPLS", "KTLEV", "MACKO", "MAKIM", "OBASE", "ONCSM", "OZSUB", "PASEU", "PLTUR", "PRDGS", "RUBNS", "SDTTR", "SEGYO", "SNICA", "SOKE", "TERA", "TNZTP"];
    let weekly = symbols500().filter(x => { return !outs.includes(x); });
    return weekly;
}



export const symbols030 = () => { return getSymbol("x030"); }
export const symbols070 = () => { return getSymbol("x070"); }
export const symbols200 = () => { return getSymbol("x200"); }
export const symbols400 = () => { return getSymbol("x400"); }

export const symbols030_weekly = () => { return getSymbol("x030_weekly"); }
export const symbols070_weekly = () => { return getSymbol("x070_weekly"); }
export const symbols200_weekly = () => { return getSymbol("x200_weekly"); }
export const symbols400_weekly = () => { return getSymbol("x400_weekly"); }