import moment from "moment";
import { fireStore } from "../../../firebase";
import { WEB_ACTION } from "./actions";
import { IData } from "./common";

const DATA_COUNT = 900;
const COLLECTION_NAME = "NS_HOURLY_PREDICTED";

interface IDailySentimentData {
  index: IData[];
  sentiment: IData[];
  sentiment2: IData[];
  momentum: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IDailySentimentData = {
  momentum: [],
  sentiment: [],
  sentiment2: [],
  index: [],
  requested: false,
  loaded: false,
};

export function loadDailyPredictedSentiment(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.DailyPredictedSentiment.requested) return;
    dispatch({
      type: WEB_ACTION.DAILY_PREDICTED_SENTIMENT,
      payload: {
        requested: true,
      },
    });

    fireStore
      .collection(COLLECTION_NAME)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        debugger;
        var sentimentData: Object[] = [];
        var sentiment2Data: Object[] = [];
        var momentumData: Object[] = [];
        var indexData: Object[] = [];
        querySnapshot.forEach(function (doc: any) {
          const dateVal = moment
          .unix(doc.data().dateTime.seconds)
          .format("YYYY/MM/DD HH:mm");
          sentimentData.push({
            name: "sentiment",
            value: [dateVal, doc.data().sentiment],
            date: doc.data().dateTime.seconds,
          });

          indexData.push({
            name: "predicted",
            value: [dateVal, doc.data().predicted],
            date: doc.data().dateTime.seconds,
          });
        });

        indexData = indexData.reverse();
        sentimentData = sentimentData.reverse();

        dispatch({
          type: WEB_ACTION.DAILY_PREDICTED_SENTIMENT,
          payload: {
            index: indexData,
            sentiment: sentimentData,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function DailyPredictedSentiment(state = initialState, action: any) {
  switch (action.type) {
    case WEB_ACTION.DAILY_PREDICTED_SENTIMENT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const dailyPredictedSentimentSelector = (state: any) => state.DailyPredictedSentiment;
