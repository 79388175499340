import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import {
  loadNotifications,
  notificationSelector,
} from "../../appRedux/reducers/sentiment/notifications";
import { useAuth } from "../../authentication";
import { firebaseApp } from "../../firebase";
import { useHistory } from "react-router-dom";
import { isProd, isStaging } from "../../util/Env";
import { isCooperateUser } from "../../appRedux/reducers/sentiment/permissions";
import { nsUserSelector } from "../../appRedux/reducers/sentiment/user";
import { isWeekPeriod } from "../../util/Period";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const dispatch = useDispatch();
  const nsUser = useSelector(nsUserSelector);
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common?.pathname);
  const { authUser } = useAuth();

  useEffect(() => {
    dispatch(loadNotifications(firebaseApp, authUser?.uid));
  }, [authUser]);

  const notifications = useSelector(notificationSelector);
  const unreadNotifications =
    notifications && notifications?.data
      ? notifications?.data?.filter((n) => !n.read).length
      : 0;
  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const { userSignOut } = useAuth();
  const history = useHistory();
  const onLogoutClick = () => {
    userSignOut(() => {
      history.push("/");
    });
  };

  const selectedKeys = pathname?.substr(1);
  const defaultOpenKeys = selectedKeys?.split("/")[1];

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <Menu.Item key="main">
              <Link to="/dashboard">
                <i className="icon icon-home" />
                <span>
                  <IntlMessages id="sidebar.home" />
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item key="index">
              <Link to="/index/home">
                <span>
                  {" "}
                  <i className="icon icon-chart-line" />
                  <IntlMessages id="sidebar.index" />
                </span>
              </Link>
            </Menu.Item>

            <Menu.SubMenu
              key="bist"
              popupClassName={getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  {" "}
                  <i className="icon icon-chart-area" />
                  <IntlMessages id="sidebar.bist" />
                </span>
              }
            >
              <Menu.Item key="bist/home">
                <Link to="/bist/home">
                  <i className="icon icon-menu" />
                  <span>
                    <IntlMessages id="sidebar.bist.home" />
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="bist/lines">
                <Link to="/bist/lines">
                  <i className="icon icon-widgets" />
                  <span>
                    <IntlMessages id="sidebar.bist.symbols" />
                  </span>
                </Link>
              </Menu.Item>


              {(
                <Menu.Item key="bist/follower">
                  <Link to="/bist/follower">
                    <i className="icon icon-filter" />
                    <span>
                      Takipçi
                    </span>
                  </Link>
                </Menu.Item>
              )}

              <Menu.Item key="bist/explore">
                <Link to="/bist/explore">
                  <i className="icon icon-search-new" />
                  <span>
                    <IntlMessages id="sidebar.bist.explorer" />
                  </span>
                </Link>
              </Menu.Item>


              <Menu.Item key="bist/signals">
                <Link to="/bist/signals">
                  <i className="icon icon-growth" />
                  <span>
                    {!isWeekPeriod() ? <IntlMessages id="sidebar.bist.first_list" /> : <IntlMessages id="sidebar.bist.first7_list" />}
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="bist/worst">
                <Link to="/bist/worst">
                  <i className="icon icon-down" />
                  <span>
                    <IntlMessages id="sidebar.bist.last_list" />
                  </span>
                </Link>
              </Menu.Item>

              {(
                  <Menu.Item key="bist/timeline">
                    <Link to="/bist/timeline">
                      <i className="icon icon-map-clustering" />
                      <span>
                        Sembol Sinyal
                      </span>
                    </Link>
                  </Menu.Item>
                )}

              {/* {!isProd() && nsUser.cooperate && (
                <Menu.Item key="bist/monitor">
                  <Link to="/bist/monitor">
                    <i className="icon icon-view-o" />
                    <span>
                      <IntlMessages id="sidebar.bist.monitor" />
                    </span>
                  </Link>
                </Menu.Item>
              )} */}
            </Menu.SubMenu>



            <Menu.SubMenu
              key="perform"
              popupClassName={getNavStyleSubMenuClass(navStyle)}
              title={
                <span>
                  {" "}
                  <i className="icon icon-icon" />
                  <IntlMessages id="page.bist.explorer.performance" />
                </span>
              }
            >

              <Menu.Item key="bist/trades">
                <Link to="/bist/trades">
                  <i className="icon icon-pricing-table" />
                  <span>
                    <IntlMessages id="sidebar.bist.first_list_history" />
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="bist/shortTrades">
                <Link to="/bist/shortTrades">
                  <i className="icon icon-pricing-table" />
                  <span>
                    <IntlMessages id="sidebar.bist.last_list_history" />
                  </span>
                </Link>
              </Menu.Item>



              {(nsUser.cooperate &&
                <Menu.Item key="bist/balances">
                  <Link to="/bist/balances">
                    <i className="icon icon-dasbhoard" />
                    <span>
                      <IntlMessages id="sidebar.bist.profit_charts" />
                    </span>
                  </Link>
                </Menu.Item>
              )}


            </Menu.SubMenu>

            {((nsUser.cooperate || nsUser?.package?.["per.exchanger"]) &&
              <Menu.SubMenu
                key="exchanger"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    {" "}
                    <i className="icon icon-chart-pie" />
                    Takascı
                  </span>
                }
              >
                <Menu.Item key="exchanger/symbol">
                  <Link to="/exchanger/symbol">
                    <i className="icon icon-extensions" />
                    <span>Sembol</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="exchanger/corporate">
                  <Link to="/exchanger/corporate">
                    <i className="icon icon-crm" />
                    <span>Kurum</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="exchanger/signal">
                  <Link to="/exchanger/signal">
                    <i className="icon icon-megaphone" />
                    <span>Sinyal</span>
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>
            )}

            {((nsUser.cooperate) && !isProd() &&
              <Menu.SubMenu
                key="zephlex"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    {" "}
                    <i className="icon icon-apps" />
                    Zephlex
                  </span>
                }
              >

                {(nsUser.cooperate && !isProd() &&
                  <Menu.Item key="bist/history">
                    <Link to="/bist/history">
                      <i className="icon icon-map-clustering" />
                      <span>
                        Geçmiş Veriler
                      </span>
                    </Link>
                  </Menu.Item>
                )}
                {(nsUser.cooperate && !isProd() &&
                  <Menu.Item key="bist/pairLines">
                    <Link to="/bist/pairLines">
                      <i className="icon icon-map-clustering" />
                      <span>
                        İkili Sembol Verisi
                      </span>
                    </Link>
                  </Menu.Item>
                )}

                {(nsUser.cooperate && !isProd() &&
                  <Menu.Item key="bist/metrics">
                    <Link to="/bist/metrics">
                      <i className="icon icon-map-clustering" />
                      <span>
                        PNL
                      </span>
                    </Link>
                  </Menu.Item>
                )}

              </Menu.SubMenu>
            )}


            {!isProd() && (
              <Menu.Item key="messages">
                <Link to="/messages">
                  <i className="icon icon-email" />
                  <span>Mesajlar</span>
                </Link>
              </Menu.Item>
            )}
            <Menu.Item key="account">
              <Link to="/account">
                <i className="icon icon-profile2" />
                <span>
                  <IntlMessages id="sidebar.account" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="bulletins">
              <Link to="/bulletins">
                <i className="icon icon-editor" />
                <span>
                  <IntlMessages id="sidebar.bulletins" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="notifications">
              <Link to="/notifications">
                <i className="icon icon-notification"></i>
                <span>
                  <IntlMessages id="sidebar.notifcations" />{" "}
                  {unreadNotifications > 0 && (
                    <span className="notification-count-badge">
                      {unreadNotifications}{" "}
                    </span>
                  )}
                </span>
              </Link>
            </Menu.Item>
            {!isProd() && (
              <Menu.Item key="alarms">
                <Link to="/alarms">
                  <i className="icon icon-alert"></i>
                  <span>Alarmlar </span>
                </Link>
              </Menu.Item>
            )}
            <Menu.Item key="help">
              <Link to="/help">
                <i className="icon icon-question-circle" />
                <span>
                  <IntlMessages id="sidebar.help" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="exit">
              <Link onClick={onLogoutClick}>
                <i className="icon icon-close-circle" />
                <span>
                  <IntlMessages id="sidebar.logout" />
                </span>
              </Link>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
        <UserProfile />
      </div>
    </>
  );
};

export default React.memo(SidebarContent);
