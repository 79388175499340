import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

const DATA_COUNT = 240;
const COLLECTION_NAME = "IND_BOTT";

interface IXUData {
  bott: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  bott: [],
  requested: false,
  loaded: false,
};

export function loadBOTT(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.BOTT.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.BOTT,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("date", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var data: Object[] = [];

        querySnapshot.forEach(function (doc: any) {

          data.push({
            mov: doc.data().mov,
            value: doc.data().value,
            vip030: doc.data().vip030,
            //date: doc.data().date.toDate(),
            date: moment(doc.data().date.toDate()).format("DD.MM.YY HH:mm")
          });
        });

        data.reverse();

        dispatch({
          type: SENTIMENT_ACTION.BOTT,
          payload: {
            bott: data,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function BOTT(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.BOTT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const BOTTselector = (state: any) => state.BOTT;
