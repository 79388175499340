import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import Common from "./Common";
import DailySuggestions from "./sentiment/daily-suggestions";
import News from "./sentiment/news";
import Portfolio from "./sentiment/portfolio";
import Signal30 from "./sentiment/signal-30";
import Signal500 from "./sentiment/signal-500";
import Signal200 from "./sentiment/signal-200";
import Signal400 from "./sentiment/signal-400";
import Signal70 from "./sentiment/signal-70";
import Trade30 from "./sentiment/trade-30";
import Trade500 from "./sentiment/trade-500";
import Trade70 from "./sentiment/trade-70";
import Trade200 from "./sentiment/trade-200";
import Trade400 from "./sentiment/trade-400";
import NsUser from "./sentiment/user";
import Xsen030DailyBalance from "./sentiment/xsen030-daily-balance";
import Xsen070DailyBalance from "./sentiment/xsen070-daily-balance";
import Xsen500DailyBalance from "./sentiment/xsen500-daily-balance";
import XU30Daily from "./sentiment/xu030";
import XU100Daily from "./sentiment/xu100";
import Xu100DailyBalance from "./sentiment/xu100-daily-balance";
import Xu030DailyBalance from "./sentiment/xu030-daily-balance";
import Settings from "./Settings";
import DailyInstantSentiment from "./web/daily-instant-sentiment";
import DailySentimentLast from "./web/daily-sentiment-last";
import DailySentiment from "./web/daily-sentiment";
import HourlySentiment from "./web/hourly-sentiment";
import Osilator from "./web/osilator";
import Senti1, { Senti1TradingView } from "./web/senti-1";
import Senti15 from "./web/senti-15";
import LinesDates from "./sentiment/lines-dates";
import BistTop30 from "./sentiment/bist-top-30";
import BistTop70 from "./sentiment/bist-top-70";
import BistPerformanceDaily from "./sentiment/bist-performance-daily";
import BistPerformanceWeekly from "./sentiment/bist-performance-weekly";
import BistPerformanceMonthly from "./sentiment/bist-performance-monthly";
import Worst30 from "./sentiment/worst-30";
import Worst70 from "./sentiment/worst-70";
import Worst500 from "./sentiment/worst-500";
import Worst400 from "./sentiment/worst-400";
import Worst200 from "./sentiment/worst-200";
import Messages from "./web/messages";
import WebUser from "./web/user";
import LinesXHarzSnapshot from "./sentiment/lines-harz-snapshot";
import Lines200Snapshot from "./sentiment/lines-200-snapshot";
import Lines400Snapshot from "./sentiment/lines-400-snapshot";
import Lines500Snapshot from "./sentiment/lines-500-snapshot";
import Lines70Snapshot from "./sentiment/lines-70-snapshot";
import Lines30Snapshot from "./sentiment/lines-30-snapshot";
import Notifications from "./sentiment/notifications";
import Senti1Snapshot from "./web/senti-1-snapshot";
import ShortTrade30 from "./sentiment/shortTrade-30";
import ShortTrade70 from "./sentiment/shortTrade-70";
import SentimentPower from "./sentiment/sentiment-power";
import LinesIndexes from "./sentiment/lines-indexes";
import StockAlarmState from "./sentiment/stock-alarm-state";
import StockAlarm from "./sentiment/stock-alarm";
import DailyHighScores from "./sentiment/daily-highscores";
import Bulletins from "./sentiment/bulletins"
import BOTT from "./sentiment/bott";
import ExchangeSignal from "./sentiment/exchangeSignal";
import ExchangeCorporateSignal from "./sentiment/exchangeCorporateSignal";
import ExchangeMoneySignal from "./sentiment/exchangeMoneySignal";
import LinesHistory from "./sentiment/lines-history";
import Timeline30 from "./sentiment/timeline-30";
import Timeline70 from "./sentiment/timeline-70";
import Timeline200 from "./sentiment/timeline-200";
import Timeline400 from "./sentiment/timeline-400";
import Timeline500 from "./sentiment/timeline-500";
import SignalXK30 from "./sentiment/signal-xk30";
import Lines103Snapshot from "./sentiment/lines-xk103-snapshot";

import Prices from "./payment/prices"
import DailyPredictedSentiment from "./web/daily-predicted";
const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    common: Common,
    DailySentiment,
    DailyInstantSentiment,
    DailySentimentLast,
    HourlySentiment,
    Osilator,
    Senti1,
    Senti15,
    XU30Daily,
    XU100Daily,
    Portfolio,
    News,
    DailySuggestions,
    LinesIndexes,
    Signal30,
    Signal70,
    Signal500,
    Signal200,
    Signal400,
    SignalXK30,
    Xsen030DailyBalance,
    Xsen070DailyBalance,
    Xsen500DailyBalance,
    Xu100DailyBalance,
    Xu030DailyBalance,
    Trade30,
    Trade70,
    Trade500,
    Trade200,
    Trade400,
    ShortTrade30,
    ShortTrade70,
    NsUser,
    LinesDates,
    BistTop30,
    BistTop70,
    BistPerformanceDaily,
    BistPerformanceWeekly,
    BistPerformanceMonthly,
    Worst30,
    Worst70,
    Worst500,
    Worst200,
    Worst400,
    Messages,
    WebUser,
    Lines30Snapshot,
    Lines70Snapshot,
    Lines500Snapshot,
    Lines200Snapshot,
    Lines400Snapshot,
    LinesXHarzSnapshot,
    Lines103Snapshot,
    Notifications,
    Senti1Snapshot,
    SentimentPower,
    Senti1TradingView,
    StockAlarmState,
    StockAlarm,
    DailyHighScores,
    Bulletins,
    BOTT,
    ExchangeSignal,
    ExchangeCorporateSignal,
    ExchangeMoneySignal,
    LinesHistory,
    Timeline30,
    Timeline70,
    Timeline500,
    Timeline200,
    Timeline400,
    Prices,
    DailyPredictedSentiment
  });

export default createRootReducer;
