import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import ImkbEndeksSenetler from "../../../util/ImkbEndeksSenetler";
import klineInterval from "./params";

const COLLECTION_NAME = "NS_LINES_500";

interface IXUData {
  lines500: IData[];
  requested: boolean;
  loaded: boolean;
  lastUpdate: number;
}

const initialState: IXUData = {
  lines500: [],
  requested: false,
  loaded: false,
  lastUpdate: 0
};

export function loadLines500Snapshot(firebase: any, period: number, count: number, historyDate: Date | null = null) {
  let isLimited = period == 50 || period == 51;
  if (period == 50)
    period = 10;
  if (period == 51)
    period = 99;
  //period = period == 50 ? 10 : period;
  if (period < 0 || ![0, 10, 30, 98, 99].includes(period)) {
    console.log("Invalid period for line 500. Period:", period);
    return async function thunk(dispatch: any, getState: any) {
      dispatch({
        type: SENTIMENT_ACTION.EMPTY_ACTION,
        payload: {},
      });
    };
  }
  return async function thunk(dispatch: any, getState: any) {
    historyDate = historyDate ?? new Date();
    const state = getState();
    if (new Date().getTime() - state.Lines500Snapshot.lastUpdate < 60 * 1000 && historyDate != null && historyDate == state.selectedDate) return;

    dispatch({
      type: SENTIMENT_ACTION.LINES_500_SNAPSHOT,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}${klineInterval}_P${period}`)
      .orderBy("dateTime", "desc")
      .where("dateTime", "<=", historyDate)
      .limit(count)
      .get().then((querySnapshot: any) => {
        var lines500Data: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          //if (lines500Data.some((x: any) => { x.Symbol == doc.data().symbol })) return;
          if (isLimited && doc.data().group != "Y") return;
          lines500Data.push({
            name: "lines500",
            Index: doc.data().index,
            Symbol: doc.data().symbol,
            Sentiment: doc.data().sentiment,
            Order: doc.data().order,
            Group: doc.data().group,
            ODate: doc.data().openDate,
            OPrice: doc.data().openPrice,
            Price: doc.data().price,
            DailyChange: doc.data().dailyChange,
            Vbts: doc.data().vbts,
            First5to10: doc.data().first5to10,
            IsNewFirst5: doc.data().isNewFirst5,
            Viop: doc.data().viop,
            PerDay: doc.data().perDay,
            SentimentList: doc.data().sentimentList,
            PriceList: doc.data().priceList,
            ChangeList: doc.data().changeList,
            ReChangeList: doc.data().changeList.slice().reverse(),
            dateTime: doc.data().dateTime.toDate(),
            Time: doc.data().time ?? 0,
            UpdateTime: moment
              .unix(doc.data().dateTime.seconds)
              .format("DD.MM.yy HH:mm"),
            LineDate: doc.data().dateTime.seconds,
            Indexes: ImkbEndeksSenetler.filter(x => x.name == doc.data().symbol)[0]?.val
          });
        });

        // var list = lines500Data.map((x: any) => {
        //   return {
        //     "Period": 24,
        //     "Xsen": 500,
        //     "Symbol": x.Symbol,
        //     "Order": x.Order,
        //     "DailyChange": x.DailyChange,
        //     "Point": x.Sentiment,
        //     "Point2": x.SentimentList[x.SentimentList.length - 2],
        //     "Sentiment": x.ChangeList[x.ChangeList.length - 1],
        //     "Sentiment2": x.ChangeList[x.ChangeList.length - 2],
        //     "Sentiment3": x.ChangeList[x.ChangeList.length - 3],
        //     "Day": moment.unix(x.LineDate).format("DD.MM.yyyy"),
        //     "Price": x.Price
        //   }
        // });
        // console.log(list);

        dispatch({
          type: SENTIMENT_ACTION.LINES_500_SNAPSHOT,
          payload: {
            lines500: lines500Data.sort((a: any, b: any) => { return a.Order - b.Order }),
            loaded: true,
            requested: true,
            lastUpdate: new Date().getTime()
          },
        });
      });
  };
}

export default function Lines500Snapshot(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.LINES_500_SNAPSHOT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const lines500SnapshotSelector = (state: any) => state.Lines500Snapshot;
